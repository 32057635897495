@tailwind base;
@tailwind components;
@tailwind utilities;
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family:
    SF Pro Display,
    sans-serif;
}
html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: auto;
  background-color: #e6e9ed;
}
#root {
  height: 100%;
  width: 100%;
  background-color: #e6e9ed;
}

#datepicker-portal-container {
  position: relative;
  z-index: 9999;
}

.react-datepicker-popper {
  z-index: 9999 !important;
}

.datepicker-popper {
  z-index: 9999 !important;
}

.react-datepicker__input-container input {
  width: 100%;
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker-popper[data-placement^='bottom'] {
  margin-top: 10px;
}

.react-datepicker-popper[data-placement^='top'] {
  margin-bottom: 10px;
}
